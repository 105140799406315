export enum PermissionEnum {
  AccountRead = 'account.read',
  AccountWrite = 'account.write',
  AccountDelete = 'account.delete',
  AssetRead = 'asset.read',
  AssetWrite = 'asset.write',
  AssetDelete = 'asset.delete',
  BankRead = 'bank.read',
  BankWrite = 'bank.write',
  BankSetActive = 'bank.setActive',
  BankDelete = 'bank.delete',
  ChartOfAccountRead='chartOfAccount.read',
  ChartOfAccountWrite='chartOfAccount.write',
  ChartOfAccountSetActive='chartOfAccount.SetActive',
  DashboardRead = 'dashboard.read',
  DepositRead = 'deposit.read',
  DepositWrite = 'deposit.write',
  DepositDelete = 'deposit.delete',
  FixedInvestmentRequestRead = 'fixedInvestmentRequest.read',
  FixedInvestmentRequestWrite = 'fixedInvestmentRequest.write',
  FixedInvestmentRequestDelete = 'fixedInvestmentRequest.delete',
  BondInvestmentRequestRead = 'bondInvestmentRequest.read',
  BondInvestmentRequestWrite = 'bondInvestmentRequest.write',
  BondInvestmentRequestDelete = 'bondInvestmentRequest.delete',
  EquityInvestmentRequestRead = 'equityInvestmentRequest.read',
  equityInvestmentRequestWrite = 'equityInvestmentRequest.write',
  EquityInvestmentRequestDelete = 'equityInvestmentRequest.delete',
  FixedInvestmentRead = 'fixedInvestment.read',
  FixedInvestmentWrite = 'fixedInvestment.write',
  FixedInvestmentDelete = 'fixedInvestment.delete',
  IncomeExpenseReportRead = 'incomeExpenseReport.read',
  FinancialReportRead = 'financialReport.read',
  BondInvestmentRead = 'bondInvestment.read',
  BondInvestmentWrite = 'bondInvestment.write',
  BondInvestmentDelete = 'bondInvestment.delete',
  EquityInvestmentRead = 'equityInvestment.read',
  EquityInvestmentWrite = 'equityInvestment.write',
  EquityInvestmentDelete = 'equityInvestment.delete',
  InvestmentFundRead = 'investmentFund.read',
  InvestmentFundWrite = 'investmentFund.write',
  InvestmentFundDelete = 'investmentFund.delete',
  BondInvestmentPaymentRead = 'bondInvestmentPayment.read',
  BondInvestmentPaymentWrite = 'bondInvestmentPayment.write',
  BondInvestmentPaymentDelete = 'bondInvestmentPayment.delete',
  EquityInvestmentPaymentRead = 'equityInvestmentPayment.read',
  EquityInvestmentPaymentWrite = 'equityInvestmentPayment.write',
  EquityInvestmentPaymentDelete = 'equityInvestmentPayment.delete',
  InvestmentFundPaymentRead = 'investmentFundPayment.read',
  InvestmentFundPaymentWrite = 'investmentFundPayment.write',
  InvestmentFundPaymentDelete = 'investmentFundPayment.delete',
  InvestmentPortfolioRead = 'investmentPortfolio.read',
  InvestmentPlanRead = 'investmentPlan.read',
  InvestmentPlanWrite = 'investmentPlan.write',
  InvestmentPlanDelete = 'investmentPlan.delete',
  InvestmentTypeRead = 'investmentType.read',
  InvestmentTypeWrite = 'investmentType.write',
  InvestmentTypeDelete = 'investmentType.delete',
  UserAccountRead = 'userAccount.read',
  UserAccountWrite = 'userAccount.write',
  UserAccountDelete = 'userAccount.delete',
  UserAccountChangePwd = 'userAccount.changePwd',
  UserAccountResetPwd = 'userAccount.resetPwd',
  UserAccountSetActive = 'userAccount.setActive',
  UserAccountChangeRole = 'userAccount.changeRole',
  RoleRead = 'role.read',
  RoleWrite = 'role.write',
  RoleSetActive = 'role.setActive',
  RoleDelete = 'role.delete',
  WorkflowRead = 'workflow.read',
  WorkflowWrite = 'workflow.write',
  WorkflowDelete = 'workflow.delete',
}
