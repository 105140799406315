import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../shared/configs/app-oauth.config';
import { Router } from '@angular/router';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

@Injectable({
  providedIn: 'root',
})
export class SingleSignOnService {
  constructor(private _oauthService: OAuthService, private _router: Router) {
    this._initFlow();
  }

  private _initFlow() {
    this._oauthService.configure(authCodeFlowConfig);
    this._oauthService.tokenValidationHandler = new JwksValidationHandler();
    this._oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  login() {
    this._oauthService.initImplicitFlow();
  }

  get isLoggedIn() {
    return !!this._oauthService.getIdToken();
  }

  refreshToken() {
    this._oauthService.refreshToken();
  }

  getAccessToken() {
    return this._oauthService.getAccessToken();
  }

  logout() {
    this._oauthService.logOut();
  }

  onLoginFailed() {
    this._router.navigateByUrl('/login');
    // this.snackbar.open("ដំណើរការចូលប្រើប្រាស់ប្រព័ន្ធមានបញ្ហា", '', { duration: 3000, horizontalPosition: 'center', panelClass: 'panel-error' });
  }
}
