<div class="breadcrumb">
  @for (item of items; track item; let idx = $index) {
  <div
    class="item"
    (click)="routeClick(item, idx)"
    [class.active]="idx === items.length - 1"
  >
    {{ item.name | translate }}
  </div>
  @if (idx !== items.length - 1) {
  <mat-icon class="separator">chevron_right</mat-icon>
  } }
</div>
